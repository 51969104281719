import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ActivitiesModule.module.css";

ActivitiesModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function ActivitiesModule({
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const { i18n } = useTranslation();
  const componentRef = useRef(null);
  const [activities, setActivities] = useState([]);
  const [randomActivity, setRandomActivity] = useState(null);
  let { width: componentWidth } = useComponentSize(componentRef);
  const isCompact = componentWidth <= 340;

  const data = useStaticQuery(graphql`
    query TombolaQuery {
      wp {
        sv: posts(where: { tag: "tombola", language: SV }, first: 10000) {
          nodes {
            id
            slug
            title
            content
            link
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
          }
        }
        en: posts(where: { tag: "tombola", language: EN }, first: 10000) {
          nodes {
            id
            slug
            title
            content
            link
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
          }
        }
        svPages: pages(where: { tag: "tombola", language: SV }, first: 10000) {
          nodes {
            id
            slug
            title
            content
            link
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
          }
        }
        enPages: pages(where: { tag: "tombola", language: EN }, first: 10000) {
          nodes {
            id
            slug
            title
            content
            link
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
          }
        }
        svEvents: allEvent(where: { tag: "tombola", language: SV }, first: 10000) {
          nodes {
            id
            slug
            title
            content
            link
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
          }
        }
        enEvents: allEvent(where: { tag: "tombola", language: EN }, first: 10000) {
          nodes {
            id
            slug
            title
            content
            link
            featuredImage {
              node {
                id
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `);  

  useEffect(() => {
    let languageData;
    if (i18n.language === 'sv') {
      languageData = [
        ...data.wp.sv.nodes,
        ...data.wp.svPages.nodes,
        ...data.wp.svEvents.nodes,
      ];
    } else if (i18n.language === 'en') {
      languageData = [
        ...data.wp.en.nodes,
        ...data.wp.enPages.nodes,
        ...data.wp.enEvents.nodes,
      ];
    }
  
    if (languageData) {
      setActivities(languageData);
      selectRandomActivity(languageData);
    }
  }, [data, i18n.language]);

  const selectRandomActivity = (activityList) => {
    if (activityList.length > 1) {
      let newActivity;
      do {
        newActivity = activityList[Math.floor(Math.random() * activityList.length)];
      } while (newActivity.id === randomActivity?.id);
      setRandomActivity(newActivity);
    } else if (activityList.length === 1) {
      setRandomActivity(activityList[0]);
    }
  };

  const handleRandomizeClick = () => {
    selectRandomActivity(activities);
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const adjustLink = (url) => {
    return url.replace("https://cms.falkenberg.se", "https://falkenberg.se");
  };


  return (
    <ModuleWrapper
      as={"div"}
      styles={styles}
      className={clsx(styles.component, isCompact && styles.isCompact)}
      {...restProps}
    >
      <div ref={componentRef}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.activityWrapper}>
          {randomActivity && (
            <>
              <div className={styles.imageWrapper}>
                {randomActivity.featuredImage?.node?.mediaItemUrl && (
                  <img
                    src={randomActivity.featuredImage.node.mediaItemUrl}
                    alt={randomActivity.title}
                    className={styles.image}
                  />
                )}
                <div className={styles.overlay}>
                  <div className={styles.textsWrapper}>
                    <h3 className={styles.activityTitle}>{randomActivity.title}</h3>
                    <p className={styles.activityDescription}>
                      {stripHtmlTags(randomActivity.content).substring(0, 150)}...
                    </p>
                    <a href={adjustLink(randomActivity.link)} className={styles.readMoreButton}>
                      {i18n.language === "sv" ? "Läs mer" : "Read more"}
                    </a>
                  </div>
                </div>
              </div>
              <button onClick={handleRandomizeClick} className={styles.randomizeButton}>
                {i18n.language === "sv" ? "Slumpa fram ditt nästa äventyr" : "Randomize your next adventure"}
              </button>
            </>
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
}
