import FormFieldDescription from "@whitespace/components/dist/components/FormFieldDescription";
import FormFieldError from "@whitespace/components/dist/components/FormFieldError";
import FormFieldWrapper from "@whitespace/components/dist/components/FormFieldWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import FormFieldLabel from "../FormFieldLabel";

import * as defaultStyles from "./FormUploadField.module.css";

FormUploadField.propTypes = {
  className: PropTypes.string,
  fieldProps: PropTypes.object,
  styles: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  handleOnChange: PropTypes.func,
};

export default function FormUploadField({
  className,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <FormFieldWrapper
      className={clsx(styles.component, className)}
      {...restProps}
    >
      {({ name, controlProps, field, helpers }) => (
        <>
          <FormFieldLabel className={styles.label} />
          <FormFieldDescription className={styles.description} />
          <FormFieldError className={styles.error} />
          <div className={styles.control}>
            <input
              type="file"
              className={styles.input}
              name={name}
              {...controlProps}
              {...field}
              onChange={(e) => {
                const file = e.currentTarget.files[0];
                helpers.setValue(file);
              }}
            />
          </div>
        </>
      )}
    </FormFieldWrapper>
  );
}
