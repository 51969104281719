/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { SkipTo, Link, Icon } from "@whitespace/components";
import HeaderFlyOutMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderFlyOutMenu";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Header.module.css";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

const homeURLs = { sv: "/", en: "/en/" };

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t, i18n } = useTranslation();
  const pageContext = usePageContext();
  let translations = pageContext?.contentNode?.translations || [];
  // let language = pageContext.contentNode.language;
  let currentLanguage = i18n.language;

  let translationLanguage = currentLanguage === "sv" ? "en" : "sv";
  let translationURL =
    translations.find(
      (translation) => translation.language.slug === translationLanguage,
    )?.uri || homeURLs[translationLanguage];

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(layout.component, layout.componentWidthFull, styles.layout)}>
        <SkipTo />
        <HeaderLogo linkTo={homeURLs[currentLanguage] || "/"} />
        <div className={clsx(styles.headerContainer)}>
          <div className={clsx(styles.linkContainerDesktop)}>
            <Link
              className={clsx(styles.link)}
              to="https://kommun.falkenberg.se/"
            >
              {t("gotoFalkenbergsKommun")}
              <Icon name="arrow-right" className={styles.arrow} />
            </Link>
          </div>
          <div className={styles.inner}>
            <div className={clsx(styles.logoPlaceholder)}/>
              <HeaderMainMenu
                menuName={"horizontalMenu"}
                aria-label={t("mainMenu")}
                className={styles.mainMenuDesktop}
              />
              <div className={clsx(styles.actions)}>
                <div className={clsx(styles.linkContainerMobile)}>
                  <Link
                    className={clsx(styles.link)}
                    to="https://kommun.falkenberg.se/"
                  >
                    {t("gotoFalkenbergsKommun")}
                    <Icon name="arrow-right" className={styles.arrow} />
                  </Link>
                </div>
                <Link
                  to={currentLanguage === "sv" ? "/sok" : "/en/search"}
                  className={clsx(styles.search)}
                  aria-label={t("siteSearchTitle")}
                  css={css`
                    display: inline-flex;
                    gap: 0.25rem;
                    align-items: center;
                    --link-text-decoration: none;
                    --link-hover-text-decoration: underline;
                  `}
                >
                  <Icon name="search" />
                </Link>
                <Link
                  to={translationURL}
                  className={clsx(styles.search)}
                  css={css`
                    display: inline-flex;
                    gap: 0.25rem;
                    align-items: center;
                    --link-text-decoration: none;
                    --link-hover-text-decoration: underline;
                  `}
                >
                  <Icon name="globe" />
                  {t(`switchLanguage.${translationLanguage}`)}
                </Link>
                <HeaderFlyOutMenu />
              </div>
            </div>
          </div>
        <HeaderMainMenu
          menuName={"horizontalMenu"}
          aria-label={t("mainMenu")}
          className={styles.mainMenuMobile}
        />
      </div>
    </header>
  );
}
