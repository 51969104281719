// extracted by mini-css-extract-plugin
export var actions = "Header-module--actions--fLKyV";
export var arrow = "Header-module--arrow--6YosI";
export var component = "Header-module--component--IzF1d";
export var headerContainer = "Header-module--headerContainer--xlX8N";
export var inner = "Header-module--inner--bJbDd";
export var layout = "Header-module--layout--OY0sZ";
export var link = "Header-module--link--l4KiS";
export var linkContainerDesktop = "Header-module--linkContainerDesktop--Fl75i";
export var linkContainerMobile = "Header-module--linkContainerMobile--T3yMz";
export var logoPlaceholder = "Header-module--logoPlaceholder--eHHXs";
export var mainMenuDesktop = "Header-module--mainMenuDesktop--zV1WX";
export var mainMenuMobile = "Header-module--mainMenuMobile--OVHka";
export var search = "Header-module--search--q-ek9";